@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: open-sans;
    src: url(/fonts/open-sans-300-normal.ttf);
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: open-sans;
    src: url(/fonts/open-sans-400-normal.ttf);
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: open-sans;
    src: url(/fonts/open-sans-500-normal.ttf);
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: open-sans;
    src: url(/fonts/open-sans-700-normal.ttf);
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: playfair-display;
    src: url(/fonts/playfair-display-400-normal.otf);
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: playfair-display;
    src: url(/fonts/playfair-display-700-normal.otf);
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: don-carlitto;
    src: url(/fonts/don-carlitto-400-normal.ttf);
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: allura;
    src: url(/fonts/allura-400-normal.ttf);
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: trajan-pro-3;
    src: url(/fonts/trajan-pro-3-400-normal.otf);
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: barlow;
    src: url(/fonts/barlow-300-normal.ttf);
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: barlow;
    src: url(/fonts/barlow-400-normal.ttf);
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: barlow;
    src: url(/fonts/barlow-500-normal.ttf);
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: barlow;
    src: url(/fonts/barlow-600-normal.ttf);
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: barlow;
    src: url(/fonts/barlow-700-normal.ttf);
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: compass-serif;
    src: url(/fonts/compass-serif-400-normal.ttf);
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: tenor-sans;
    src: url(/fonts/tenor-sans-400-normal.ttf);
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: kristas;
    src: url(/fonts/kristas-400-normal.ttf);
    font-weight: 400;
    font-style: normal;
  }

  .description {
    @apply font-['Inter'] text-[1rem] leading-[1.4rem];
  }

  .mobile-body-small {
    @apply font-['Inter'] text-[1rem] leading-[1.4rem];
  }

  .body-small {
    @apply font-['Inter'] text-[1.2rem] leading-[1.6rem];
  }

  .body-medium {
    @apply font-['Inter'] text-[1.4rem] leading-[2rem];
  }

  .body-large {
    @apply font-['Inter'] text-[1.6rem] leading-[2.2rem];
  }

  .heading-6 {
    @apply font-montserrat text-[1.8rem] leading-[2.4rem];
  }

  .heading-5 {
    @apply font-montserrat text-[2rem] leading-[2.8rem];
  }

  .heading-4 {
    @apply font-montserrat text-[2.4rem] leading-[3.2rem];
  }

  .heading-3 {
    @apply font-montserrat text-[2.8rem] leading-[4rem];
  }

  .heading-2 {
    @apply font-montserrat text-[3.2rem] leading-[4.4rem];
  }

  .heading-1 {
    @apply font-montserrat text-[3.6rem] leading-[5rem];
  }

  /* @apply doesn't work with "!" prefix for tailwind classes */

  .\!description {
    font-family: "Inter" !important;
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }

  .\!mobile-body-small {
    font-family: "Inter" !important;
    font-size: 1rem !important;
    line-height: 1.4rem !important;
  }

  .\!body-small {
    font-family: "Inter" !important;
    font-size: 1.2rem !important;
    line-height: 1.6rem !important;
  }

  .\!body-medium {
    font-family: "Inter" !important;
    font-size: 1.4rem !important;
    line-height: 2rem !important;
  }

  .\!body-large {
    font-family: "Inter" !important;
    font-size: 1.6rem !important;
    line-height: 2.2rem !important;
  }

  .\!heading-6 {
    font-family: "montserrat" !important;
    font-size: 1.8rem !important;
    line-height: 2.4rem !important;
  }

  .\!heading-5 {
    font-family: "montserrat" !important;
    font-size: 2rem !important;
    line-height: 2.8rem !important;
  }

  .\!heading-4 {
    font-family: "montserrat" !important;
    font-size: 2.4rem !important;
    line-height: 3.2rem !important;
  }

  .\!heading-3 {
    font-family: "montserrat" !important;
    font-size: 2.8rem !important;
    line-height: 4rem !important;
  }

  .\!heading-2 {
    font-family: "montserrat" !important;
    font-size: 3.2rem !important;
    line-height: 4.4rem !important;
  }

  .\!heading-1 {
    font-family: "montserrat" !important;
    font-size: 3.6rem !important;
    line-height: 5rem !important;
  }

  .focusable {
    @apply focus:outline-none focus-visible:ring-4 focus-visible:ring-primary-500 focus-visible:ring-opacity-50;
  }

  .link {
    @apply font-montserrat font-medium body-small text-new-primary-400 hover:!text-new-primary-300 outline-none cursor-pointer whitespace-nowrap transition duration-200 ease-in-out focusable;
  }
}

*,
*:focus,
*:hover {
  outline: none;
}

html,
body {
  font-size: 10px;
}

body.fub-slim-ui {
  min-width: 260px;
  min-height: 600px;
  overflow-y: hidden;
}

h1 {
  font-family: "montserrat", sans-serif;
  font-size: 3.6rem;
  line-height: 5rem;
}

h2 {
  font-family: "montserrat", sans-serif;
  font-size: 3.2rem;
  line-height: 4.4rem;
}

h3 {
  font-family: "montserrat", sans-serif;
  font-size: 2.8rem;
  line-height: 4rem;
}

h4 {
  font-family: "montserrat", sans-serif;
  font-size: 2.4rem;
  line-height: 3.2rem;
}

h5 {
  font-family: "montserrat", sans-serif;
  font-size: 2rem;
  line-height: 2.8rem;
}

h6 {
  font-family: "montserrat", sans-serif;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

input[type="text"]:placeholder-shown {
  text-overflow: ellipsis;
}

a.bb-link,
span.bb-link {
  cursor: pointer;
  color: theme("colors.primary-600");
}

a.bb-link:hover,
span.bb-link:hover {
  color: theme("colors.primary-700");
}

.contact-gallery {
  grid-template-areas:
    "area-1 area-1 area-1 area-1 area-2 area-2 area-2 area-2 area-3 area-3 area-3 area-3 area-4 area-4 area-4 area-4 area-4 area-4 area-4 area-4 area-5 area-5 area-5 area-5 area-6 area-6  area-7 area-7 area-8 area-8"
    "area-1 area-1 area-1 area-1 area-2 area-2 area-2 area-2 area-3 area-3 area-3 area-3 area-4 area-4 area-4 area-4 area-4 area-4 area-4 area-4 area-5 area-5 area-5 area-5 area-13 area-13 area-13 area-14 area-14 area-14"
    "area-9 area-9 area-9 area-9 area-10 area-10 area-10 area-10 area-11 area-11 area-11 area-11 area-4 area-4 area-4 area-4 area-4 area-4 area-4 area-4 area-12 area-12 area-12 area-12 area-13 area-13 area-13 area-14 area-14 area-14"
    "area-9 area-9 area-9 area-9 area-10 area-10 area-10 area-10 area-11 area-11 area-11 area-11 area-4 area-4 area-4 area-4 area-4 area-4 area-4 area-4 area-12 area-12 area-12 area-12 area-13 area-13 area-13 area-14 area-14 area-14";
}

.contact-gallery,
.gallery-image-1 {
  grid-area: area-1;
}

.contact-gallery,
.gallery-image-2 {
  grid-area: area-2;
}

.contact-gallery,
.gallery-image-3 {
  grid-area: area-3;
}

.contact-gallery,
.gallery-image-4 {
  grid-area: area-4;
}

.contact-gallery,
.gallery-image-5 {
  grid-area: area-5;
}

.contact-gallery,
.gallery-image-6 {
  grid-area: area-6;
}

.contact-gallery,
.gallery-image-7 {
  grid-area: area-7;
}

.contact-gallery,
.gallery-image-8 {
  grid-area: area-8;
}

.contact-gallery,
.gallery-image-9 {
  grid-area: area-9;
}

.contact-gallery,
.gallery-image-10 {
  grid-area: area-10;
}

.contact-gallery,
.gallery-image-11 {
  grid-area: area-11;
}

.contact-gallery,
.gallery-image-12 {
  grid-area: area-12;
}

.contact-gallery,
.gallery-image-13 {
  grid-area: area-13;
}

.contact-gallery,
.gallery-image-14 {
  grid-area: area-14;
}

@keyframes template5ScaleUp {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@keyframes template5SlideUp {
  from {
    margin-bottom: -100%;
  }

  to {
    margin-bottom: 0%;
  }
}

.content-to-edit {
  border: 0.1rem dashed #ffbda4;
  position: relative;
}

.content-to-edit::before {
  content: "Editable Content";
  position: absolute;
  top: -1.5rem;
  left: -0.1rem;
  background-color: #ffbda4;
  display: block;
  font-size: 1rem;
  color: #000;
  text-align: center;
  height: 1.5rem;
  line-height: 1.5rem;
  padding: 0rem 0.5rem;
}

.introjs-tooltip {
  border-radius: 1rem !important;
}

.introjs-tooltip-title {
  display: none !important;
}

.introjs-skipbutton {
  display: none !important;
}

.introjs-tooltiptext {
  padding: 15px !important;
}

.introjs-bullets {
  padding-top: 0px !important;
}

.custom-accordion-preview ul {
  list-style: disc !important;
}

.upload-signature .ant-upload.ant-upload-select {
  width: 500px !important;
  height: 200px !important;
}

.header-homepage-gradient {
  background: linear-gradient(89deg, #b9e7e8 0.1%, #cdfbef 31.51%, #d9eef8 65.35%, #cfdaf7 98.82%);
}

.header-selected-lead-gradient {
  background: linear-gradient(89deg, #e9e5fa 0%, #eff7fd 35.95%, #e3feff 62.19%, #cbe8ff 100%);
}

.header-selected-training-lead-gradient {
  background: linear-gradient(89deg, #e5f0db 0%, #f3fcf8 35.95%, #fdf5ff 62.19%, #f9e5ff 100%);
}

/* DEPRECATED */
.PhoneInput {
  display: flex;
  gap: 0.5rem;
}
.PhoneInput .PhoneInputCountry {
  width: 30px;
  border: 1px solid #e0e0e0;
  padding: 2px 2px 0px 2px;
}
.PhoneInput .PhoneInputCountry + input {
  width: 100%;
}
.PhoneInput .PhoneInputCountrySelect {
  display: none;
}
